import React from 'react';
import '../../styles/pages/landing-page.css';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ExpansionValve from '../../images/expansion-valve.png';
import Evaporator from '../../images/evaporator.png';
import Condenser from '../../images/vaporator.png';
import BlowMotor from '../../images/blow-motor.jpg';
import CoolingFan from '../../images/cooling-fan.jpg';
import Ac from '../../images/ac-with-filter.jpg';
import Engine from '../../images/engine-with-filter.jpg';

// Components
import Header from '../header';
import Layout from '../layout';
import CarouselDisplay from '../carousel-display';
import PostCard from '../post-card';

const landing = {
  en: {
    news: 'News',
    products: 'Products',
    productTitle: {
      ac: 'Air conditioning',
      cs: 'Cooling System',
    },
    acProducts: [
      { title: 'Expansion Valve', path: '/en/products/intro/expansion-valve', img: ExpansionValve },
      { title: 'Compressor', path: '/en/products/intro/compressor', img: ExpansionValve },
      { title: 'Filter Dryer', path: '/en/products/intro/filter-dryer', img: ExpansionValve },
      { title: 'Condenser', path: '/en/products/intro/condenser', img: Condenser },
      { title: 'Blow Motor', path: '/en/products/intro/blow-motor', img: BlowMotor },
      { title: 'Evaporator', path: '/en/products/intro/evaporator', img: Evaporator },
    ],
    ecProducts: [
      { title: 'Radiator', path: '/en/products/intro/radiator' },
      { title: 'Cooling Fan', path: '/en/products/intro/cooling-fan' },
    ],
  },
  zh: {
    news: '最新消息',
    products: '產品',
    productTitle: {
      ac: '空調系統',
      cs: '冷卻系統',
    },
    acProducts: [
      { title: '膨脹閥', path: '/products/intro/expansion-valve-zh', img: ExpansionValve },
      { title: '壓縮機', path: '/products/intro/compressor-zh', img: ExpansionValve },
      { title: '乾燥過濾器', path: '/products/intro/filter-dryer-zh', img: ExpansionValve },
      { title: '冷凝器', path: '/products/intro/condenser-zh', img: Condenser },
      { title: '鼓風機馬達', path: '/products/intro/blow-motor-zh', img: BlowMotor },
      { title: '蒸發器', path: '/products/intro/evaporator-zh', img: Evaporator },
    ],
    ecProducts: [
      { title: '散熱器', path: '/products/intro/radiator-zh' },
      { title: '冷卻風扇', path: '/products/intro/cooling-fan-zh' },
    ],
  },
};

const LandingPage = (props) => {
  const {
    pageContext: { langKey },
    data: {
      news: { edges },
      sliders,
    },
  } = props;

  const viewmorePath = (lang) => {
    switch (lang) {
      case 'en':
        return '/en/news';
      case 'zh':
        return '/news';
      default:
        return null;
    }
  };
  return (
    <Layout>
      <CarouselDisplay carousel={sliders} />
      <Container style={{ marginTop: '50px' }}>
        <Header title={landing[langKey].news} viewmore path={viewmorePath(langKey)} />
        <Row>
          {edges &&
            edges.map((edge) => <PostCard lg={4} md={6} sm={12} post={edge} key={edge.node.frontmatter.title} />)}
        </Row>
        {/* <div style={{ marginTop: '50px' }}>
          <Header title={landing[langKey].products} />
          <p className="product-title-mobile-tablet">{landing[langKey].productTitle.ac}</p>
          <div className="ac-container">
            <div className="ac-left ac-1">
              <img src={Ac} width="auto" height="100%" alt="ac" />
              <p className="ac-left-title">{landing[langKey].productTitle.ac}</p>
            </div>
            {landing[langKey].acProducts.map((ac, i) => (
              <Link to={ac.path} className={`ac ac-${i + 2}`} key={ac.title}>
                <img src={ac.img} alt={ac.title} />
                <p className="ac-title">{ac.title}</p>
              </Link>
            ))}
          </div>
          <p className="product-title-mobile-tablet">{landing[langKey].productTitle.cs}</p>
          <div className="cs-container">
            {landing[langKey].ecProducts.map((ec, i) => (
              <Link to={ec.path} className={`cs cs-${i + 1}`} key={ec.title}>
                <img src={CoolingFan} height="100%" alt={ec.title} />
                <p className="cs-title">{ec.title}</p>
              </Link>
            ))}
            <div className="cs-right cs-3">
              <img src={Engine} alt="engine" />
              <p className="cs-right-title">{landing[langKey].productTitle.cs}</p>
            </div>
          </div>
        </div> */}
      </Container>
    </Layout>
  );
};

export default LandingPage;
