import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Img from 'gatsby-image';

const CarouselDisplay = ({ carousel }) => {
  const {
    frontmatter: { sliders },
  } = carousel;
  return (
    <div style={{ marginTop: '-50px' }}>
      <Carousel>
        {sliders.map((s) => (
          <Carousel.Item key={s.title}>
            <Img fluid={s.sliderImg.childImageSharp.fluid} />
            <Carousel.Caption>
              <h3>{s.title}</h3>
              <p>{s.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselDisplay;
