import React from 'react';
import '../styles/post-card.css';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Col from 'react-bootstrap/Col';

// Components
import Label from './label';

const PostCard = ({ lg, md, sm, post }) => {
  const {
    node: {
      frontmatter: { title, date, tags, featured, locale },
      excerpt,
      fields: { slug },
    },
  } = post;
  const month = date.substring(0, 3);
  const day = date.substring(4, 6);
  const renderNewsPath = (lang, postSlug) => {
    const path = `/news/${postSlug}`;
    switch (lang) {
      case 'en':
        return `/en${path}`;
      case 'zh':
        return path;
      default:
        return null;
    }
  };
  const renderTagPath = (lang, tag) => {
    const path = `/news/tag/${tag}`;
    switch (lang) {
      case 'en':
        return `/en${path}`;
      case 'zh':
        return path;
      default:
        return null;
    }
  };
  return (
    <Col sm={sm} md={md} lg={lg} style={{ padding: '15px' }}>
      <div className="PostCard">
        <div className="PostCard--date">
          {month}
          <br />
          {day}
        </div>
        <div className="PostCard__img">
          <Img fluid={featured.childImageSharp.fluid} />
        </div>
        <div className="PostCard__caption">
          <h4 className="PostCard__caption--title">
            <Link to={renderNewsPath(locale, slug)}>{title}</Link>
          </h4>

          <div className="PostCard__caption--divider" />
          <p className="PostCard__caption--content">{excerpt}</p>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {tags &&
              tags.map((tag) => (
                <Link to={renderTagPath(locale, tag)} key={tag}>
                  <Label label={tag} />
                </Link>
              ))}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default PostCard;
